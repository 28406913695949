.dashboard {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    position: relative;
  }
  
.dashboard h1 {
    text-align: center;
    color: #333;
  }
  
.dashboard .logout-link {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 0.8em;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  
.dashboard .csv-link {
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 0.8em;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  
.dashboard .form-group {
    flex: 1;
    margin-right: 10px;
  }
  
.dashboard label {
    margin-bottom: 5px;
    color: #555;
  }
  
.dashboard .current-date,
.dashboard .current-time {
    text-align: center;
    font-size: 2em; /* フォントサイズを大きく */
    margin-bottom: 15px;
  }
  
.dashboard .button-row {
    display: flex;
    justify-content: space-between;
    align-items: center; /* 高さを揃えるために追加 */
    width: 100%;
  }
  
.dashboard .button-row select {
    width: 24%; /* プルダウンの幅を調整 */
    height: 42px; /* 高さをボタンに合わせる */
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
.dashboard .button-row button {
    width: 24%; /* ボタンの幅を調整 */
    height: 42px; /* 高さをプルダウンに合わせる */
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
.dashboard .button-row .clock-in-button {
    background-color: #007bff;
  }
  
.dashboard .button-row .clock-in-button:hover {
    background-color: #0056b3;
  }
  
.dashboard .button-row .clock-out-button {
    background-color: #28a745;
  }
  
.dashboard .button-row .clock-out-button:hover {
    background-color: #218838;
  }
  
.dashboard .button-row .apply-button {
    background-color: #ffc107;
  }
  
.dashboard .button-row .apply-button:hover {
    background-color: #e0a800;
  }
  
.dashboard ul {
    list-style: none;
    padding: 0;
  }
  
.dashboard li {
    padding: 10px;
    margin-bottom: 5px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
.dashboard li.saturday {
    color: blue;
  }
  
.dashboard li.sunday {
    color: red;
  }
  
.dashboard li.holiday {
    color: red;
  }
  
.dashboard li.weekday-no-record {
    background-color: #ffebcd; /* 薄オレンジ色 */
  }
  
.dashboard li .record-details {
    display: flex;
    justify-content: space-between;
    width: 100%; /* 左側の5分の4のスペースから全幅に変更 */
  }
  
.dashboard li .record-details div {
    flex: 1;
    text-align: center;
  }
  
.edit-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
    width: 300px;
  }
  
.edit-dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
.edit-dialog h3 {
    margin: 0;
  }
  
.close-button {
    cursor: pointer;
    font-size: 1.2em;
  }
  
.edit-dialog .form-group {
    margin-bottom: 15px;
  }
  
.edit-dialog .button-row {
    display: flex;
    justify-content: space-between;
  }
  
.edit-dialog .button-row button {
    width: 48%;
    padding: 8px; /* 高さを低くしました */
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 14px; /* フォントサイズを少し小さくしました */
    cursor: pointer;
  }
  
.edit-dialog .button-row button:hover {
    background-color: #0056b3;
  }
  
.edit-dialog .button-row .delete-button {
    background-color: #dc3545;
  }
  
.edit-dialog .button-row .delete-button:hover {
    background-color: #c82333;
  }

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    width: 100%; /* 横幅を広く設定 */
    max-width: 300px; /* 必要に応じて最大幅を設定 */
    margin: 0 auto; /* 中央に配置 */
  }
  
.navigation button {
    background-color: #003366; /* 背景色を紺色に設定 */
    color: white; /* テキスト色を白に設定 */
    border: none;
    font-size: 1.2em; /* ボタンのフォントサイズを小さく */
    cursor: pointer;
    flex: 0 0 50px; /* ボタンの幅を固定 */
    border-radius: 4px; /* 角を丸くする */
    padding: 5px; /* 内側の余白を追加 */
    margin: 0 5px; /* 端に寄せるための外側の余白を追加 */
  }
  
.navigation span {
    font-size: 1.2em; /* フォントサイズを小さく */
    font-weight: bold;
    flex: 1; /* 年月表示の幅を確保 */
    text-align: center;
  }

